<template>
  <div class="card full-page">
    <div class="card-body inner-scroll">
      <div class="thanks-wrapper">
        <h2>
          {{ $t('generic_welcome', { plan: `${$t(plan)}` }) }}
        </h2>
        <div class="thanks-content">
          <div v-if="plan === 'plan_basic'">
            <p>{{ $t('basic_welcome_2') }}</p>
            <p>{{ $t('basic_welcome_3') }}</p>
            <p>{{ $t('pro_welcome_4') }}</p>
          </div>
          <div v-else-if="plan === 'plan_pro'">
            <p>{{ $t('pro_welcome_1') }}</p>
            <p>{{ $t('pro_welcome_2') }}</p>
            <p>{{ $t('pro_welcome_3') }}</p>
            <p>{{ $t('pro_welcome_4') }}</p>
          </div>
          <div v-show="screenshotURL" class="screenshot-wrapper">
            <svg
              width="100%"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="
            0
            0
            632
            32
          "
              xmlns:xlink="http://www.w3.org/1999/xlink"
              style="border-radius: 8px 8px 0px 0px; background-color: rgb(54, 56, 58)"
            >
              <g fill="none" fill-rule="nonzero">
                <rect width="100%" fill="#36383a"></rect>
                <circle stroke="#F04744" fill="#FF5F56" cx="20" cy="17" r="6"></circle>
                <circle stroke="#EBA03F" fill="#FFBD2E" cx="40" cy="17" r="6"></circle>
                <circle stroke="#13AB42" fill="#27C93F" cx="60" cy="17" r="6"></circle>
              </g>
            </svg>
            <img
              :src="screenshotURL"
              alt="Screenshot of provider details page"
              class="screenshot"
            />
          </div>
        </div>
        <b-button class="cta-button" variant="primary" size="lg" @click="goToDashboard">
          {{ $t('Get started') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import MyProfileService from '@/services/MyProfileService'

export default {
  name: 'Thanks',
  components: {
    BButton,
  },
  data() {
    return {
      plan: this.$route.query.plan,
      payment_frequency: this.$route.query.period,
      MyProfileService: new MyProfileService(),
    }
  },
  computed: {
    screenshotURL() {
      return `${process.env.VUE_APP_SCREENSHOT_SERVICE_URL}?url=${process.env.VUE_APP_FRONT_URL}/${this.$i18n.locale}/provider-details/${this.$store.state.auth.user.slug}`
    },
  },
  methods: {
    async activatePlan() {
      const res = await this.MyProfileService.activatePlan({
        plan: this.plan,
        payment_frequency: this.payment_frequency,
      })

      this.$posthog.capture(
        'Subscribe for paid plan',
        {
          plan: res.user.plan,
          period: res.user.payment_frequency,
          trial_ends_at: res.user.trial_ends_at,
          $set: {
            plan: res.user.plan,
            period: res.user.payment_frequency,
            trial_ends_at: res.user.trial_ends_at,
          },
        },
        {
          send_instantly: true,
        }
      )

      await this.$store.dispatch('auth/fetchUserData')
    },
    goToDashboard() {
      this.$router.replace('/overview')
    },
  },
  mounted() {
    if (this.$route.query.redirect_status === 'succeeded') {
      this.activatePlan()
    }
  },
}
</script>

<style scoped>
.thanks-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.thanks-wrapper h2 {
  margin: 0;
  font-size: 1.5rem;
}

::v-deep .thanks-wrapper p {
  font-size: 1rem;
  color: #222;
}

.thanks-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.screenshot-wrapper {
  max-width: 100%;
  min-width: calc(50% - 16px);
  align-self: center;
  border-radius: 8px;
  position: relative;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.screenshot {
  width: 100%;
  aspect-ratio: 16 / 10;
  border-radius: 0px 0px 8px 8px;
}

.cta-button {
  max-width: 100%;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .thanks-wrapper {
    gap: 48px;
  }

  .thanks-wrapper h2 {
    margin: 0;
    font-size: 2rem;
  }

  ::v-deep .thanks-wrapper p {
    font-size: 1.25rem;
  }

  .thanks-content {
    flex-direction: row;
    gap: 32px;
  }

  .screenshot-wrapper {
    max-width: 50%;
  }

  .cta-button {
    max-width: 220px;
    margin: auto;
  }
}
</style>
